export default {
	data: {
		selectedImageIndex: undefined
	},
	beforeMount: function () {
		//console.warn("ha", window.location.search);
		var url = new URL(window.location.href);
		var params = {};
		for (let key of url.searchParams.keys()) {
			//console.log("p", key, url.searchParams.get(key));
			params[key] = url.searchParams.get(key);

		}
		this.searchParams = params;
	},
	mounted: function () {
		var totalBlogs = $(".blog-card-v1").length;
		console.log('totalBlogs::' + totalBlogs);
		if (totalBlogs > 0) {
			$("#message").hide();
		} else {
			$("#message").show();
		}

		$(".owl-carousel.blogcarousel").owlCarousel({
			loop: false,
			rewind: true,
			margin: 30,
			smartSpeed: 1000,
			autoplay: false,
			nav: true,
			responsive: {
				0: {
					items: 1
				},
				768: {
					items: 2
				},
				996: {
					items: 3

				}
			}
		});

	},
	computed: {

		mailto: function () {
			return `mailto:${this.emailAddress}`;
		},
		emailAddress: function () {
			return (VOFFICE.contactEmail || ['info', 'test.de']).join('@');
		}
	},

};